*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Montserrat';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0% 5%;
  width:90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size:2rem;
  text-align:center;
}

h4{
  font-size:1rem;
  color: rgb(56,56,56);
}
h3{
  font-size:1rem;
  color: rgb(56,56,56);
  line-height: 2.0rem;
  margin: 1rem 0rem 0.5rem 0rem;
  text-align:center;
}
a {
  color: rgb(56,56,56);
}
.App{
  width:100%;
}
.browserStyleText{
    font-size:1rem;
    text-align:center;
    margin:1rem 0 3rem 0;
    font-style:italic;
}
@media (max-width: 1200px) {
  body {
  margin: 0% 4%;
  width: 92%;
  }
}
@media (max-width: 480px) {
  body {
  margin: 0% 4%;
  width: 92%;
  }
  .browserStyleText{
    font-size:0.7rem;
    text-align:center;
    margin-top:1rem;
  }
}
